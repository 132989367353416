import React from 'react'

import './loadingSpinner.css'

const LoadingSpinner = () => {
  return (
    <div className="la-ball-spin-fade-rotating">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
export default LoadingSpinner
