import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ErrorPage from 'screens/Error'
import Garmin from 'screens/Garmin'

const RootNav = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/garmin_oauth_confirmation" component={Garmin} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default RootNav
