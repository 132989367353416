import React from 'react'

import './button.css'

interface IProps {
  onClick?: () => void
}

const Button: React.FC<IProps> = ({ children, onClick }) => {
  return (
    <button className="button" onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
