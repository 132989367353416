import { Endpoints, getBaseHeaders } from '../helpers'

interface IStoreGarminAccessToken {
  oauth_request_token: string
  oauth_verifier: string
  tenantId: string
}

/**
 * returns a boolean indicating if we successfully stored
 * the oauth token on the API.
 */
export const storeGarminAccessToken = async ({
  tenantId,
  oauth_request_token,
  oauth_verifier,
}: IStoreGarminAccessToken) => {
  const URL = `${Endpoints.apiRoot}/tenants/${tenantId}/garmin_oauth/access_token`

  const response = await fetch(URL, {
    method: 'POST',
    headers: getBaseHeaders(),
    body: JSON.stringify({
      oauth_request_token,
      oauth_verifier,
    }),
  })

  return response.status === 200
}
