import React from 'react'

import LoadingSpinner from 'components/LoadingSpinner'
import { useLocation } from 'react-router'

import { ReactComponent as OptLogo } from 'assets/images/logo.svg'
import './garmin.css'
import { storeGarminAccessToken } from 'utils/API/OAuthResource'
import Button from 'components/Button'
import { createDynamicLink } from 'utils/DynamicLink'

/**
 * We should expect a URL like this to render this routed component:
 * http://localhost:3000/garmin_oauth_confirmation?tenantId=1234&oauth_token=1234&oauth_verifier=1234
 */
const GarminPage = (props: any) => {
  const location = useLocation()
  const [asyncStatus, updateAsyncStatus] = React.useState<
    'IDLE' | 'LOADING' | 'ERROR' | 'SUCCESS'
  >('IDLE')

  const getGarminURLParams = () => {
    const params = new URLSearchParams(location.search)
    return {
      tenantId: params?.get('tenantId'),
      oauth_token: params?.get('oauth_token'),
      oauth_verifier: params?.get('oauth_verifier'),
    }
  }

  const handleGarminOAuth = async () => {
    try {
      if (asyncStatus !== 'IDLE') return null
      updateAsyncStatus('LOADING')
      const { tenantId, oauth_token, oauth_verifier } = getGarminURLParams()

      if (!tenantId || !oauth_token || !oauth_verifier) {
        throw new Error('Optivio Error: Failed to get URL Params.')
      }

      const response = await storeGarminAccessToken({
        tenantId,
        oauth_verifier,
        oauth_request_token: oauth_token,
      })

      if (!response) {
        return updateAsyncStatus('ERROR')
      } else {
        return updateAsyncStatus('SUCCESS')
      }
    } catch (error) {
      updateAsyncStatus('ERROR')
    }
  }

  React.useEffect(() => {
    handleGarminOAuth()
  }, [])

  return (
    <div className="app">
      <OptLogo className="opt-logo" />

      {asyncStatus === 'LOADING' && (
        <>
          <p className="garmin-page_loading-text">
            We are validating your garmin access. Please stay on the page. Don’t
            refresh or leave
          </p>
          <div className="landing-page__loading-spinner-wrapper">
            <LoadingSpinner />
          </div>
        </>
      )}

      {asyncStatus === 'ERROR' && (
        <p className="garmin-page_error-text">
          We were unable to store your Garmin OAuth Credentials. Please contact
          Support.
        </p>
      )}

      {asyncStatus === 'SUCCESS' && (
        <>
          <p className="garmin-page_success-text">
            Success! Your settings are saved and you can return to the Mobile
            App
          </p>
          <Button
            onClick={() => {
              const link = createDynamicLink('/garmin-success')
              return window.open(link)
            }}
          >
            Return to App
          </Button>
        </>
      )}
    </div>
  )
}

export default GarminPage
