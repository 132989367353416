/**
 * used within dynamic link to open app
 * each env has a unique link
 */
const FIREBASE_URL = {
  DEV: 'https://optivio.page.link',
  QA: 'https://optivioqaapp.page.link',
  UAT: 'https://optiviouatapp.page.link',
  PROD: 'https://optivio.page.link',
}

/**
 * App bundle id, also used in dynamic link to open app
 */
const IBI = {
  DEV: 'com.optiviobetaapp.optivio',
  QA: 'com.optivioqaapp.optivio',
  UAT: 'com.optiviouatapp.optivio',
  PROD: 'com.optivio.optivio',
}

/**
 * used in dynamic link if we can't open the app
 */
const FALLBACK_LINK = {
  DEV: 'https://dev.optivio-dev.com',
  QA: 'https://qa.optivio-dev.com',
  UAT: 'https://uat.optivio-dev.com',
  PROD: 'https://app.optivio.com/',
}

type TENV = 'DEV' | 'QA' | 'UAT' | 'PROD' | null | undefined

/**
 * Creates a link to open the mobile app based on the env
 * @param path if you need to add a nested path
 */
export const createDynamicLink = (path: string = '') => {
  const appEnv = (process.env.REACT_APP_ENV as TENV) || 'DEV'

  return `${FIREBASE_URL[appEnv]}/?ibi=${IBI[appEnv]}&link=${FALLBACK_LINK[appEnv]}${path}`
}
